import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { bool, number, shape, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Builder } from '@builder.io/react';
import { isEqual } from 'lodash';
import {
  AdminButton,
  AuthenticationModal,
  BuilderSection,
  FormattedMessage,
  IconFavorite,
  IconLinkTo,
  NamedLink,
  ShoppingBagButton,
  TopbarLogo,
} from '..';
import { AdminPageTab } from '../../containers/AdminPage/AdminPage.utils';
import TopbarTransparencyContext from '../../context/TopbarTransparencyContext';
import { useShopConfig } from '../../hooks/shopConfig';
import { useActionCopy } from '../../hooks/useActionCopy';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useIsShopSideLaunched } from '../../hooks/useIsShopSideLaunched';
import { useShoppingBag } from '../../hooks/useShoppingBag';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import { RequestStatus } from '../../types/requestStatus';
import { TopbarLink, TopbarSection } from '../../types/shopConfig/shopConfigV2';
import { withViewport } from '../../util/contextHelpers';
import TypographyWrapper, { TypographyFormat } from '../TypographyWrapper/TypographyWrapper';
import ProfileMenu from './ProfileMenu';
import { ModalType, setActiveModal } from '../../ducks/modal.duck';
import { Feature } from '../../util/featureFlags';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import TopbarSearch from '../Topbar/TopbarSearch';
import { BuilderSections } from '../../util/builder';
import { fetchShoppingBagListings } from '../../ducks/shoppingBag.duck';
import { getCartListingsFromPG } from '../../util/cartListingHelpers';
import AppContext from '../../context/AppContext';
import { cartListingIdsUpdated } from '../../ducks/user.duck';
import css from './TopbarDesktop.module.css';

// Based off of manually inspected window widths for long logos
const MAX_MEDIUM_SCREEN_WIDTH = 1260;
const MAX_SMALL_SCREEN_WIDTH = 990;

const Logo = () => {
  const isTransparentTopbar = useContext(TopbarTransparencyContext);
  return (
    <div
      className={classNames(css.section, css.logoSection, {
        [css.transparentTopbarForeground]: isTransparentTopbar,
      })}
    >
      <TopbarLogo />
    </div>
  );
};

const CategoryLink = ({ categoryConfig }) => {
  const isTransparentTopbar = useContext(TopbarTransparencyContext);

  // Search Query; check for null because it can be empty string
  if (categoryConfig.searchQuery == null || categoryConfig.hideOnTopbar) return null;

  return (
    <NamedLink
      name="SearchPage"
      to={{ search: categoryConfig.searchQuery }}
      className={css.link}
      key={categoryConfig.label}
    >
      <span
        className={classNames(css.linkFont, css.label, {
          [css.transparentTopbarForeground]: isTransparentTopbar,
        })}
      >
        <TypographyWrapper
          variant="subtitle1"
          format={TypographyFormat.HoverUnderlined}
          component="span"
          // Need this because element becomes misaligned when converting component to span
          typographyOverrides={{ style: { display: 'flex' } }}
        >
          {categoryConfig.label}
        </TypographyWrapper>
      </span>
    </NamedLink>
  );
};

const ShopAll = () => {
  const { css: brandCss } = useShopConfig();
  const topbarLayout = brandCss?.topbarLayout;

  return (
    <CategoryLink
      categoryConfig={{
        label:
          topbarLayout.find((section) => section.type === TopbarLink.ShopAll)?.config?.label ||
          'Shop All',
        searchQuery: 'mode=raw-query',
      }}
    />
  );
};

const ListItemLink = ({ viewport }) => {
  const { isListTradeInOnly } = useEnabledCustomerExperiences();
  const isTransparentTopbar = useContext(TopbarTransparencyContext);
  const { listActionCopy } = useActionCopy();

  const { width } = viewport;
  const hasViewport = width > 0;
  const isSmallWidth = hasViewport && width <= MAX_SMALL_SCREEN_WIDTH;
  const listItemCopy = isSmallWidth ? 'Sell' : listActionCopy;

  return (
    <div className={css.sectionWrapper}>
      <NamedLink
        className={css.sectionLink}
        name={isListTradeInOnly ? 'ManageTradeInsPage' : 'NewListingPage'}
      >
        <span
          className={classNames(css.label, {
            [css.transparentTopbarForeground]: isTransparentTopbar,
          })}
        >
          <TypographyWrapper variant="subtitle1" format={TypographyFormat.HoverUnderlined}>
            {listItemCopy}
          </TypographyWrapper>
        </span>
      </NamedLink>
    </div>
  );
};

const ListItemLinkWithViewport = withViewport(ListItemLink);

const IconLinks = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const { currentUser } = userState;
  const { activeModal } = useSelector((state) => state.modal);
  const { isAuthenticated } = useSelector((state) => state.Auth);

  const { treetId } = useContext(AppContext);
  const isSearchModalEnabled = useFeatureFlags(Feature.SearchBarV2);
  const { shoppingBagCount } = useShoppingBag();
  const { isAdmin, isBrand, isAccounting } = useCurrentUserPermissions();
  const isShopSideLaunched = useIsShopSideLaunched();
  const isBrandHealthDashboardLaunched = useFeatureFlags(Feature.BrandHealthDashboard);
  const { allowBuy, hasOrderHistory } = useEnabledCustomerExperiences();

  const isTransparentTopbar = useContext(TopbarTransparencyContext);

  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  const toggleShoppingBagOpen = async () => {
    if (!currentUser) {
      setIsAuthModalOpen(true);
    } else {
      dispatch(setActiveModal(ModalType.ShoppingBag));
      const stateCartListingIds = userState.cartListingIds || [];
      const pgCartListingIds = await getCartListingsFromPG(currentUser, treetId);
      if (!isEqual(stateCartListingIds, pgCartListingIds)) {
        await dispatch(cartListingIdsUpdated(pgCartListingIds));
        dispatch(fetchShoppingBagListings());
      }
    }
  };

  return (
    <Box display="flex" alignItems="center" height="100%">
      {isSearchModalEnabled && allowBuy && isShopSideLaunched && (
        <div
          className={classNames(css.sectionWrapper, {
            [css.transparentTopbarForeground]: isTransparentTopbar,
          })}
        >
          <TopbarSearch className={css.sectionLink} />
        </div>
      )}
      {allowBuy && currentUser && (
        <div
          className={classNames(css.sectionWrapper, {
            [css.transparentTopbarForeground]: isTransparentTopbar,
          })}
        >
          <NamedLink className={css.sectionLink} name="FavoritesPage" title="Favorites Page">
            <IconFavorite />
          </NamedLink>
        </div>
      )}
      <ProfileMenu isAuthenticatedOnClientSide={mounted && isAuthenticated} />
      {allowBuy && isShopSideLaunched && (
        <ShoppingBagButton
          count={shoppingBagCount}
          isOpen={activeModal === ModalType.ShoppingBag}
          onClick={toggleShoppingBagOpen}
          className={css.shoppingBagButton}
          rootClassName={classNames(css.sectionWrapper, {
            [css.transparentTopbarForeground]: isTransparentTopbar,
          })}
          isTransparentTopbar={isTransparentTopbar}
        />
      )}
      {(isAdmin || isBrand || isAccounting) && (
        <div
          className={classNames(css.sectionWrapper, {
            [css.transparentTopbarForeground]: isTransparentTopbar,
          })}
        >
          <AdminButton
            className={css.sectionLink}
            params={{
              // eslint-disable-next-line no-nested-ternary
              tab: isBrandHealthDashboardLaunched
                ? AdminPageTab.Home
                : hasOrderHistory
                ? AdminPageTab.MarketplaceDashboard
                : AdminPageTab.TradeInDashboard,
            }}
          />
        </div>
      )}
      <AuthenticationModal
        open={isAuthModalOpen}
        handleClose={() => setIsAuthModalOpen(false)}
        onSuccess={() => setIsAuthModalOpen(false)}
        bannerText="Sign up or log in to view your shopping bag."
      />
    </Box>
  );
};

const MainSiteLink = () => {
  const { shopName, mainSite } = useShopConfig();
  const isTransparentTopbar = useContext(TopbarTransparencyContext);

  return (
    <a className={css.link} href={mainSite} key="main-site">
      <span
        className={classNames(css.linkFont, css.label, {
          [css.transparentTopbarForeground]: isTransparentTopbar,
        })}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <TypographyWrapper variant="subtitle1" format={TypographyFormat.HoverUnderlined}>
            {shopName} Main Site
          </TypographyWrapper>
          <Box pl="4px" />
          <IconLinkTo />
        </Box>
      </span>
    </a>
  );
};

const MainSiteLinkShortened = (props) => {
  const { textColor } = props;
  const { shopName, mainSite } = useShopConfig();
  const isTransparentTopbar = useContext(TopbarTransparencyContext);

  return (
    <a className={css.link} href={mainSite} key="main-site">
      <span
        className={classNames(css.linkFont, css.label, {
          [css.transparentTopbarForeground]: isTransparentTopbar,
        })}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <TypographyWrapper
            variant="subtitle1"
            format={TypographyFormat.HoverUnderlined}
            typographyOverrides={{ style: { color: textColor } }}
          >
            {shopName}
          </TypographyWrapper>
          <Box pl="4px" />
          <IconLinkTo />
        </Box>
      </span>
    </a>
  );
};

const OtherSiteLink = () => {
  const { css: brandCss } = useShopConfig();
  const topbarLayout = brandCss?.topbarLayout;
  const otherSiteConfig = topbarLayout.find(
    (section) => section.type === TopbarLink.OtherSite
  )?.config;

  return (
    <a className={css.link} href={otherSiteConfig.url} key="other-site">
      <span className={classNames(css.linkFont, css.label)}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <TypographyWrapper variant="subtitle1" format={TypographyFormat.HoverUnderlined}>
            {otherSiteConfig.label}
          </TypographyWrapper>
        </Box>
      </span>
    </a>
  );
};

const SizeGuideLink = () => {
  const { sizeGuideUrl } = useShopConfig();
  return (
    <a className={css.link} href={sizeGuideUrl} key="size-guide">
      <span className={classNames(css.linkFont, css.label)}>
        <TypographyWrapper variant="subtitle1">Size Guide</TypographyWrapper>
      </span>
    </a>
  );
};

const TopbarCategories = () => {
  const { homepageCategories } = useShopConfig();
  return homepageCategories.map((categoryConfig) => (
    <CategoryLink categoryConfig={categoryConfig} key={categoryConfig.label} />
  ));
};

const SignUpAndLogInLinks = () => {
  const isTransparentTopbar = useContext(TopbarTransparencyContext);
  return (
    <Box display="flex" alignItems="center" height="100%">
      <NamedLink
        name="SignupPage"
        className={classNames(css.signupLink, {
          [css.transparentTopbarForeground]: isTransparentTopbar,
        })}
      >
        <span className={classNames(css.linkFont, css.label)}>
          <TypographyWrapper variant="subtitle1" format={TypographyFormat.HoverUnderlined}>
            <FormattedMessage id="TopbarDesktop.signup" />
          </TypographyWrapper>
        </span>
      </NamedLink>
      <NamedLink
        name="LoginPage"
        className={classNames(css.loginLink, {
          [css.transparentTopbarForeground]: isTransparentTopbar,
        })}
      >
        <span className={classNames(css.linkFont, css.label)}>
          <TypographyWrapper variant="subtitle1" format={TypographyFormat.HoverUnderlined}>
            <FormattedMessage id="TopbarDesktop.login" />
          </TypographyWrapper>
        </span>
      </NamedLink>
    </Box>
  );
};

const TopbarDesktopComponent = (props) => {
  const { className, rootClassName, isAuthenticated, viewport } = props;

  const { homepageCategories, css: brandCss, builderConfig } = useShopConfig();
  const topbarLayout = brandCss?.topbarLayout;
  const { allowList } = useEnabledCustomerExperiences();
  const isShopSideLaunched = useIsShopSideLaunched();
  const isTransparentTopbar = useContext(TopbarTransparencyContext);
  const { currentUserShowStatus } = useSelector((state) => state.user);

  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  const { width } = viewport;
  const hasViewport = width > 0;
  const isSmallWidth = hasViewport && width <= MAX_SMALL_SCREEN_WIDTH;

  const getTopbarLinks = () => {
    const shouldShowShopAll = !isSmallWidth && isShopSideLaunched;

    const isMediumWidth =
      hasViewport && width <= MAX_MEDIUM_SCREEN_WIDTH && width > MAX_SMALL_SCREEN_WIDTH;
    const isMediumOrSmallWidth = isMediumWidth || isSmallWidth;
    const shouldShowCategories = !(isMediumOrSmallWidth && homepageCategories.length > 0);
    const otherSiteConfig = topbarLayout.find(
      (section) => section.type === TopbarLink.OtherSite
    )?.config;
    const shouldShowOtherSite =
      !!otherSiteConfig && (!isMediumOrSmallWidth || otherSiteConfig.alwaysShow);

    return {
      [TopbarLink.ShopAll]: shouldShowShopAll ? <ShopAll /> : null,
      [TopbarLink.Categories]: shouldShowCategories ? <TopbarCategories /> : null,
      [TopbarLink.SizeGuide]: <SizeGuideLink />,
      [TopbarLink.MainSite]: <MainSiteLink />,
      [TopbarLink.OtherSite]: shouldShowOtherSite ? <OtherSiteLink /> : null,
    };
  };

  const classes = classNames(rootClassName || css.root, className);
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const topbarLinks = getTopbarLinks();
  const hasTopbarLinksRight = topbarLayout.some(
    (topbarLink) => topbarLink.section === TopbarSection.Right
  );

  const topbarContentSectionId = builderConfig?.sections?.[BuilderSections.TopbarContent];

  if (topbarContentSectionId) {
    return (
      <BuilderSection
        sectionType={BuilderSections.TopbarContent}
        sectionId={topbarContentSectionId}
        sectionData={{ isLoggedIn: isAuthenticatedOrJustHydrated }}
      />
    );
  }

  return (
    <nav>
      <Box className={classes} width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mx="auto"
          className={css.contentWrapper}
        >
          <div className={classNames(css.section, css.shopSection)}>
            {hasViewport && (
              <>
                {topbarLayout
                  .filter((topbarLink) => topbarLink.section === TopbarSection.Left)
                  .map((topbarLink) => (
                    <div
                      key={topbarLink.type}
                      className={classNames(css.linkSection, {
                        [css.transparentTopbarForeground]: isTransparentTopbar,
                      })}
                    >
                      {topbarLinks[topbarLink.type]}
                    </div>
                  ))}
              </>
            )}
          </div>
          <Logo />
          <div className={classNames(css.section, css.linksSection)}>
            {hasTopbarLinksRight && (
              <div className={css.sectionWrapper}>
                {topbarLayout
                  .filter((topbarLink) => topbarLink.section === TopbarSection.Right)
                  .map((topbarLink) => (
                    <div
                      key={topbarLink.type}
                      className={classNames(css.linkSection, {
                        [css.transparentTopbarForeground]: isTransparentTopbar,
                      })}
                    >
                      {topbarLinks[topbarLink.type]}
                    </div>
                  ))}
              </div>
            )}
            {allowList && <ListItemLinkWithViewport />}
            {!isAuthenticatedOrJustHydrated && <SignUpAndLogInLinks />}
            {currentUserShowStatus === RequestStatus.Success && <IconLinks />}
          </div>
        </Box>
      </Box>
    </nav>
  );
};

TopbarDesktopComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

TopbarDesktopComponent.propTypes = {
  rootClassName: string,
  className: string,
  isAuthenticated: bool.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

Builder.registerComponent(Logo, { name: 'Logo' });
Builder.registerComponent(ShopAll, { name: 'Shop All Link' });
Builder.registerComponent(MainSiteLink, { name: 'Main Site Link' });
Builder.registerComponent(MainSiteLinkShortened, {
  name: 'Main Site Link Shortened',
  inputs: [{ name: 'textColor', type: 'text' }],
});
Builder.registerComponent(OtherSiteLink, { name: 'Other Site Link' });
Builder.registerComponent(SizeGuideLink, { name: 'Size Guide Link' });
Builder.registerComponent(ListItemLinkWithViewport, { name: 'List Item Link' });
Builder.registerComponent(SignUpAndLogInLinks, { name: 'Sign Up And Log In Links' });
Builder.registerComponent(TopbarCategories, { name: 'Category Links' });
Builder.registerComponent(IconLinks, { name: 'Icon Links' });

const TopbarDesktop = withViewport(TopbarDesktopComponent);

export default TopbarDesktop;
