/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */

/*
 * This component is a modified version of the TermsOfService component,
 * created as a one-off request for Heath.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box } from '@material-ui/core';
import { TypographyWrapper } from '..';
import { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import css from './HeathTermsOfService.module.css';

const HeathTermsOfService = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <Box className={classes}>
      <TypographyWrapper variant="body1">Last updated: October 10, 2024</TypographyWrapper>

      <TypographyWrapper variant="h2">1. What is Treet?</TypographyWrapper>
      <TypographyWrapper variant="body1">
        Treet is an online platform, marketplace, and Service where Users can buy and sell Items
        from each other in exchange for cash, discounts, or credit to the Partner Site.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">2. Application & Enforceability</TypographyWrapper>
      <TypographyWrapper variant="body1">
        By accessing, viewing, or using Treet or any tools and services, including purchasing or
        selling Items, provided in connection with Treet or the Partner Site, you understand and
        agree to be bound by all terms and conditions contained in these Terms of Use. If you object
        to anything in these Terms of Use, including the mandatory arbitration provision in Section
        16 and class action waiver in Section 17, or otherwise do not understand or agree to be
        bound by these Terms of Use, do not use the Service or create an account.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">3. User Obligations</TypographyWrapper>
      <TypographyWrapper variant="body1">
        We grant you a limited, non-exclusive, non-transferable, and revocable license to use Our
        Services—subject to the Terms and the following restrictions in particular:
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ display: 'inline' }}
        >
          You are responsible for your account.
        </TypographyWrapper>{' '}
        To use many of the Services, you will need to register and create an account with a username
        and password. You must provide us with accurate and complete information, and you must
        update your account information as needed to keep the information accurate and complete. You
        may not impersonate anyone else, choose a username that may offend someone, or violate any
        individual’s rights. You are solely responsible for maintaining the confidentiality of your
        account and for all activities associated with or occurring under your account. If you
        suspect or discover any unauthorized use of your account, you should notify Treet
        immediately by contacting us at support@treet.co. We are not responsible for any loss or
        damage arising from your failure to comply with the foregoing requirements or as a result of
        use of your account with or without your knowledge.
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ display: 'inline' }}
        >
          Terminating your account.
        </TypographyWrapper>{' '}
        You may submit a request to terminate your account by contacting us at support@treet.co.
        This will not automatically delete User Content. We may need to retain certain information
        as required by law or as necessary for Our business purposes. These Terms, including our
        rights to User Content, survive termination of an account.
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ display: 'inline' }}
        >
          Be truthful.
        </TypographyWrapper>{' '}
        Do not post information you know is false, misleading, or inaccurate. Do not do anything
        deceptive or fraudulent. Respect the rights of others. You may not threaten, abuse, harass,
        defame, or engage in behavior that is libelous, tortious, obscene, profane, or invasive of
        another’s privacy. Do not engage in activities that affect the functioning of the Site. You
        may not bypass any measures that We have put in place to secure Our Site or Services, take
        actions to gain unauthorized access to any system, data, passwords, or other Treet or User
        information or Services, reverse engineer or take apart any aspect of the Services to access
        any underlying information, or use any kind of software to “crawl” or “spider” any part of
        the Site.
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ display: 'inline' }}
        >
          Fees.
        </TypographyWrapper>{' '}
        There are no fees for creating an account on the Site. Sellers may be charged a platform fee
        as a percentage of the Selling Price.
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        Should you violate any of the Terms of Use, We reserve the right to terminate, suspend, or
        take any other action related to your account at Our sole discretion. You may not access Our
        Site or Services if We have prohibited you from doing so.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">4. Treet’s Role</TypographyWrapper>
      <TypographyWrapper variant="body1">
        While Treet may review and approve Posts before becoming available on the Site, Treet does
        not claim any ownership over the Items being exchanged between Users. We may remove Posts
        and issue refunds at Our discretion. Treet may remove User Content that violates the Terms
        at its sole discretion. Removing User Content, terminating an account, or canceling
        transactions are not actions We take lightly, and We may take awhile to investigate and
        determine if such actions are warranted. It is at Treet’s sole discretion whether to discuss
        the reasons for taking action against any User Content or account. Neither We nor Partner
        guarantees the quality of Items being exchanged. We do not become involved in legal disputes
        between Users. Treet bears no liability, and has no obligation to investigate, participate,
        or become involved, in any dispute between Users, including between Buyers and Sellers.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">5. Seller Obligations</TypographyWrapper>
      <TypographyWrapper variant="body1">
        As a Seller on Treet, when you create a Post on the Site, you understand that you are
        entering into separate legal agreements with both Treet and with Buyers, and the following
        rules apply (in addition to all other Terms).
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        You agree that you own and have rights to sell the Item being posted on Treet. You will make
        good faith efforts to fulfill the items associated with a Post in the timeframe that is
        communicated. You will immediately notify Treet if there are obstacles or delays.
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        You are responsible for complying with all laws and regulations that apply to Items being
        posted for sale on Treet and the Partner Site, including California’s Proposition 65 (“Prop
        65”). If your state has additional requirements or regulations, it is your responsibility to
        take the actions required for compliance. The Prop 65 regulations generally provide that a
        warning should contain the following information:
      </TypographyWrapper>

      <ul className={css.prop65List}>
        <li>
          <TypographyWrapper variant="body1">
            A statement that the product may expose you to a Prop 65 listed chemical
          </TypographyWrapper>
        </li>
        <li>
          <TypographyWrapper variant="body1">
            The name of at least one chemical listed as known to cause cancer and/or one chemical
            listed as known to cause reproductive harm that prompted the warning
          </TypographyWrapper>
        </li>
        <li>
          <TypographyWrapper variant="body1">
            The Internet address for OEHHA’s Proposition 65 warnings website,{' '}
            <a href="https://www.P65Warnings.ca.gov" target="_blank" rel="noreferrer">
              www.P65Warnings.ca.gov
            </a>
          </TypographyWrapper>
        </li>
        <li>
          <TypographyWrapper variant="body1">
            {' '}
            A triangular yellow warning symbol ⚠ (on most warnings) and the word “WARNING” in all
            caps
          </TypographyWrapper>
        </li>
        <li>
          <TypographyWrapper variant="body1">
            For example, if your product contains lead, the warning would be:{' '}
            <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
              ⚠️ WARNING: This product can expose you to chemicals including lead, which is known to
              the State of California to cause cancer and birth defects or other reproductive harm.
              For more information go to P65Warnings.ca.gov.
            </TypographyWrapper>
          </TypographyWrapper>
        </li>
      </ul>

      <TypographyWrapper variant="body1">
        You are responsible for reviewing the Prop 65 regulations and for complying with them.
        Neither Treet nor Partner has any responsibility, liability or obligation for your
        compliance or lack thereof and only you shall have liability to Buyers for your failure to
        comply.
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ display: 'inline' }}
        >
          Be responsive.
        </TypographyWrapper>{' '}
        Respond promptly and truthfully to all questions posed by Buyers and any questions or
        requests Treet makes.
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ display: 'inline' }}
        >
          Be truthful and transparent.
        </TypographyWrapper>{' '}
        Sellers should be ready, willing, and able to substantiate claims your Posts make, including
        but not limited to the quality, sizing, and all additional Post descriptions. If you are
        unable to substantiate claims, Treet may terminate your account, withhold funds, or other
        actions to enforce its rights under this Agreement and applicable law.
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ display: 'inline' }}
        >
          Respect privacy.
        </TypographyWrapper>{' '}
        When you use the Services by creating a Post, you may receive information about other Users,
        including personally identifiable information (“PII”), such as names, e-mail addresses, and
        physical addresses. This information is provided to you purely for the purpose of fulfilling
        the Items for the applicable Post and other Services, and may not be used or disclosed for
        other purposes.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">6. Platform Fees</TypographyWrapper>
      <TypographyWrapper variant="body1">
        Sellers may be subjected to a platform fee based on the Selling Price and will be deducted
        prior to funds being sent. Fees may vary depending on the Partner’s Disbursement Policy
        posted on their respective Sites. Treet reserves the right to change the fees at any time
        and such changes will be reflected in our Partner’s Disbursement Policy.{' '}
      </TypographyWrapper>

      <TypographyWrapper variant="h2">7. Shipping</TypographyWrapper>
      <TypographyWrapper variant="body1">
        Sellers on Treet will be provided a prepaid shipping label and must ship their items to the
        Buyer within seven days of the purchase date. If the Seller does not ship the item within
        seven days, Treet reserves the right to cancel the transaction and provide a full refund to
        the Buyer. At Treet’s sole discretion, they may terminate a Seller account for continuously
        missing their Shipping window. Sellers must only use the provided prepaid shipping label to
        ship their purchased Item and is wholly responsible for abiding by the conditions of the
        shipping carrier.{' '}
      </TypographyWrapper>

      <TypographyWrapper variant="h2">8. Refunds & Returns</TypographyWrapper>
      <TypographyWrapper variant="body1">
        If there is an issue with an Item that arrives, such as the item arriving significantly
        different than described in the Post, Buyers must file a Misrepresentation Claim with Treet
        within 72 hours of receiving the item. If the Claim is accepted, Treet will provide the
        Buyer with a prepaid return shipping label for the Buyer to ship the item within 72 hours.
        Once the item is detected as being in transit, Treet will issue a refund based on the cost
        of purchasing the item.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">9. Taxes</TypographyWrapper>
      <TypographyWrapper variant="body1">
        Applicable taxes may be collected upon purchasing Items on Treet based on the jurisdiction
        in which the purchase is made. In the event that taxes are collected at checkout, Treet will
        submit the taxes to the proper taxing authorities on the Buyers behalf.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">10. Prohibited Items</TypographyWrapper>
      <TypographyWrapper variant="body1">
        Any listing or sale of an item that is illegal to sell online under any applicable statute,
        ordinance, law, or regulation, is strictly prohibited by Treet. Items must also abide by the
        shipping carrier’s Domestic Shipping Prohibitions & Restrictions. While Items sold on Treet
        may be used, no item can be significantly damaged; all Items must be clean and in good
        condition. Treet also prohibits posting any fake, counterfeit, or otherwise fraudulent
        Items. Partner reserves the right, but has no obligation, to determine which items can and
        cannot be posted on the Partner Sites, so long as the Items are not prohibited by this
        Section. Partner has no obligation to verify that any Item is compliant with any applicable
        statute, ordinance, law, or regulation. Sellers are solely responsible for ensuring
        compliance.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">
        11. Brand Credit, Discount Codes, & Gift Cards
      </TypographyWrapper>
      <TypographyWrapper variant="body1">
        Treet may offer Sellers the ability to redeem funds as brand credit with Partner in the form
        of discount codes or gift cards. The policy of using the respective discount codes or gift
        cards is wholly determined by Partner and Treet is not responsible for any items purchased
        using the discount codes or gift cards.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">12. Your Intellectual Property</TypographyWrapper>
      <TypographyWrapper variant="body1">
        Your User Content remains your property. When you submit User Content to the Site or via the
        Services, you agree to the following terms:
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        You allow us to use your User Content. You grant us a worldwide, non-exclusive, perpetual,
        irrevocable, royalty-free, sublicensable, and transferable right to use, exercise,
        commercialize, and exploit the copyright, publicity, trademark, and database rights with
        respect to your User Content. We can make changes to or delete your User Content. You grant
        us the right to make changes, edits, modifications, translations, formatting, or delete your
        User Content. You have all legal rights to your User Content. You represent and warrant
        that: (1) you have all licenses, rights, consents, and permissions necessary to grant the
        rights set forth in these Terms to Treet with respect to your User Content; (2) your User
        Content does not and will not infringe any third party’s intellectual property rights,
        proprietary rights, privacy rights, confidentiality, rights of publicity or otherwise
        violate these Terms or applicable law; and (3) Treet does not need to obtain any licenses,
        rights, consents, or permissions from, or make any payments to, any third party for any use
        of your User Content, or have any liability to a User or any other party as a result of Our
        use or exploitation of your User Content.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">
        13. Treet’s Rights Concerning User Accounts; Termination
      </TypographyWrapper>
      <TypographyWrapper variant="body1">
        We reserve the right to monitor, terminate, suspend, or delete any User Account at any time
        in Our sole discretion. We have no obligation to comment on any reasons for account
        monitoring, termination, suspension, or deletion. Neither We nor Partner have any liability
        to you or third parties for any use of the Services associated with your account. Upon
        suspension or termination of your account, you must cease all use of the Services and User
        Content. Your representations and warranties shall survive termination. Treet reserves the
        right to suspend or terminate a Post if it does not comply with this agreement or in its
        sole discretion. In the event of termination, Treet may refund any and all funds received by
        a Seller and may seek reimbursement from you for any funds you have already received from
        Treet. Treet may also avail itself of any of the remedies provided for in this Agreement and
        any remedies available to enforce its rights under this Agreement or applicable law.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">14. Payment Services</TypographyWrapper>
      <TypographyWrapper variant="body1">
        Credit card payment processing services for Sellers on Treet are provided by Stripe, Inc.
        (“Stripe”) and are subject to the Stripe Connected Account Agreement, which includes the
        Stripe Terms of Service (collectively, the “Stripe Services Agreement”). As a Seller, you
        agree to be bound by the Stripe Services Agreement, as may be modified by Stripe from time
        to time. As a condition to Treet enabling credit card payment processing services through
        Stripe, you agree to provide Treet accurate and complete information about yourself and you
        authorize Treet to share any such information with Stripe, as well as transaction
        information related to your use of the Stripe payment processing services. We are not
        responsible for the performance of any third-party credit card processing or third-party
        payment services.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">15. Disclaimer of Liability</TypographyWrapper>
      <TypographyWrapper variant="body1">
        Use the Services at your own risk. Neither We nor Partner makes any representations about
        the quality, safety, morality or legality of any Post or Item or the truth or accuracy of
        User Content posted on the Services. We do not guarantee the fulfillment or the quality of
        any Items. Neither We nor Partner guarantees in any manner the reliability, validity,
        accuracy or truthfulness of such User Content. Neither Treet nor Partner is liable for any
        damages or losses related to your use of the Services. You release us and Partner from all
        claims. When you use the Services, you release Treet and Partner from claims, damages, and
        demands of every kind - known and unknown, suspected or unsuspected, disclosed or
        undisclosed - arising out of or related to the Services. All Content that you access or use
        through the Services is entirely at your own risk and you solely are responsible for any
        resulting damage or loss to any party.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">16. Arbitration</TypographyWrapper>
      <TypographyWrapper variant="body1">
        Any controversy, claim, suit, injury, harm, loss, or damage arising from or in any way
        related to the use or provision of the Service or these Terms, shall only be resolved by
        binding arbitration and conducted in accordance with these Terms of Use and the Commercial
        Arbitration Rules and Arbitration Procedures of the American Arbitration Association (“AAA”)
        then in effect, which can be obtained at any AAA office, at www.adr.org, or by calling the
        AAA at 1-800-778-7879. If these is any inconsistency between the AAA Rules and this
        arbitration provision, the terms of the arbitration provision will control unless the
        arbitrator determines that the application of the inconsistent terms would result in a
        fundamentally unfair arbitration. This arbitration provision will be construed and enforced
        in accordance with the Federal Arbitration Act, 9 U.S.C. §1 et seq.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">17. Class Action Waiver</TypographyWrapper>
      <TypographyWrapper variant="body1">
        No Class or Representative Actions. In the event that a tribunal or arbitrator of competent
        jurisdiction determines that the arbitration provision is unenforceable, you agree that the
        exclusive forum and/or venue for any controversy, claim, suit, injury, harm, loss or damage
        arising from or in any way related to the use, inability to use or provision of the Service
        or these Terms of Use shall be any California State Court or Federal Court sitting in San
        Francisco County, California and you hereby submit to the exclusive jurisdiction of those
        courts for purposes of any such proceeding.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">18. Definitions</TypographyWrapper>
      <TypographyWrapper variant="body1">
        “Treet”, “We”, “Our”, or “Us” refers to Treet, Inc., a Delaware corporation, together with
        its parents, subsidiaries, affiliates, agents, representatives, consultants, employees,
        officers, and directors; “Services” refers to the Site, mobile applications or connected
        applications, other offerings and services provided on the Site; “User”, “You”, or “ Your”
        refers to Buyers, Sellers or any other visitor to the Site or Users of the Services, either
        individually or collectively; “Items” are the individual articles the Seller is listing on
        the Site for sale; “Partners” refers to Heath Ceramics Ltd., and its owners, shareholders,
        parents, subsidiaries, affiliates, agents, representatives, consultants, employees,
        officers, and directors, a separate entity and brand that is using Treet’s Services;
        “Partner Site” refers to www.passtheplate.heathceramics.com, which Partner has contracted
        Treet’s Services for managing the exchange of specific Items between Users. “Site” refers to
        any website or Partner Site using Treet’s Services; “Terms” or “Terms of Use” refers to the
        Terms of Use, Additional Policies, Treet’s Privacy Policy, all applicable laws, and all
        conditions or policies referenced here; “User Content” refers to Content uploaded,
        transmitted or posted to the Services by a User, including User Content in a Post. “Posts”
        are Sellers’ Item listings through the Services; “Buyers” refers to those Users purchasing
        Items from Sellers; “Sellers” are those Users who sell their items through the Site and
        Services; “Selling Price” refers to the price an Item is purchased for; “Partner’s
        Disbursement Policy” refers to the policy that Partner determines for how their Sellers
        receive funds. “PII” refers to personally-identifiable information, as that term is defined
        under all applicable laws.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">19. Full Agreement Between You and Us</TypographyWrapper>
      <TypographyWrapper variant="body1">
        These Terms are the entire agreement between You and Treet with respect to the Services.
        They supersede all other communications and proposals (whether oral, written, or electronic)
        between you and Treet with respect to the Services and govern our relationship. If any
        provision of these Terms are deemed invalid by a court of competent jurisdiction, the
        invalidity of such provision shall not affect the validity of the remaining provisions of
        this Agreement, which shall remain in full force and effect. Treet’s failure to assert any
        right or provision under this Agreement shall not constitute a waiver of such right or
        provision.
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        You understand and agree the Treet may revise, modify, and/or supplement these terms of Use
        (the “Revised Terms of Use”) at any time. Any Revised Terms of Use will be effective
        immediately after being posted here with a date of when the revision has occurred.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">20. Third-Party Beneficiaries</TypographyWrapper>
      <TypographyWrapper variant="body1">
        Partner is a third-party beneficiary to these Terms and shall have the right to enforce the
        provisions of this Agreement and receive benefits as specified herein. Any amendments to the
        Terms that affect the rights or obligations of Partner shall require Partner’s written
        consent.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">21. Miscellaneous</TypographyWrapper>
      <TypographyWrapper variant="body1">
        We may modify or discontinue the Services at any time, in our sole discretion. No waiver of
        any provision of these Terms shall be deemed a further or continuing waiver of such term or
        any other term, and Treet’s failure to assert any right or provision under these Terms shall
        not constitute a waiver of such right or provision. You agree that regardless of any statute
        or law to the contrary, any claim arising out of or related to the Services must commence
        within one (1) year after the cause of action accrues. Otherwise, such cause of action is
        permanently barred.
      </TypographyWrapper>
    </Box>
  );
};

HeathTermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

HeathTermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default HeathTermsOfService;
