import isEmpty from 'lodash/isEmpty';
import Cookies from 'js-cookie';
// eslint-disable-next-line import/no-cycle
import { clearCurrentUser, fetchCurrentUser } from './user.duck';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import { denormalisedResponseEntities } from '../util/data';
import { apolloClient } from '../apollo';
import { CreateUserDocument, Locale } from '../types/apollo/generated/types.generated';
import { handle } from '../util/helpers';
import { trackSignUp } from '../util/heap';
import { publishCustomerEvent } from '../util/api';

const authenticated = (authInfo) => authInfo && authInfo.isAnonymous === false;

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/Auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/Auth/AUTH_INFO_SUCCESS';

export const SET_AUTH_MODAL_TRIGGER = 'app/Auth/SET_AUTH_MODAL_TRIGGER';

export const LOGIN_REQUEST = 'app/Auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/Auth/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'app/Auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/Auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/Auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/Auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/Auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/Auth/SIGNUP_ERROR';

export const CONFIRM_REQUEST = 'app/Auth/CONFIRM_REQUEST';
export const CONFIRM_SUCCESS = 'app/Auth/CONFIRM_SUCCESS';
export const CONFIRM_ERROR = 'app/Auth/CONFIRM_ERROR';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';

// ================ Reducer ================ //

const initialState = {
  isAuthenticated: false,

  // scopes associated with current token
  authScopes: [],

  // auth info
  authInfoLoaded: false,

  // auth modal
  authModalTrigger: null,

  // login
  loginError: null,
  loginInProgress: false,

  // logout
  logoutError: null,
  logoutInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,

  // confirm (create user with idp)
  confirmError: null,
  confirmInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_INFO_REQUEST:
      return state;
    case AUTH_INFO_SUCCESS:
      return {
        ...state,
        authInfoLoaded: true,
        isAuthenticated: authenticated(payload),
        authScopes: payload.scopes,
      };

    case SET_AUTH_MODAL_TRIGGER:
      return { ...state, authModalTrigger: payload };

    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      };
    case LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true };
    case LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case LOGOUT_REQUEST:
      return { ...state, logoutInProgress: true, loginError: null, logoutError: null };
    case LOGOUT_SUCCESS:
      return { ...state, logoutInProgress: false, isAuthenticated: false, authScopes: [] };
    case LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case SIGNUP_REQUEST:
      return { ...state, signupInProgress: true, loginError: null, signupError: null };
    case SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false };
    case SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };

    case CONFIRM_REQUEST:
      return { ...state, confirmInProgress: true, loginError: null, confirmError: null };
    case CONFIRM_SUCCESS:
      return { ...state, confirmInProgress: false, isAuthenticated: true };
    case CONFIRM_ERROR:
      return { ...state, confirmInProgress: false, confirmError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const authenticationInProgress = (state) => {
  const { loginInProgress, logoutInProgress, signupInProgress, confirmInProgress } = state.Auth;
  return loginInProgress || logoutInProgress || signupInProgress || confirmInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = (info) => ({ type: AUTH_INFO_SUCCESS, payload: info });

export const setAuthModalTrigger = (trigger) => ({
  type: SET_AUTH_MODAL_TRIGGER,
  payload: trigger,
});

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = (error) => ({ type: LOGIN_ERROR, payload: error, error: true });

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = (error) => ({ type: LOGOUT_ERROR, payload: error, error: true });

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = () => ({ type: SIGNUP_SUCCESS });
export const signupError = (error) => ({ type: SIGNUP_ERROR, payload: error, error: true });

export const confirmRequest = () => ({ type: CONFIRM_REQUEST });
export const confirmSuccess = () => ({ type: CONFIRM_SUCCESS });
export const confirmError = (error) => ({ type: CONFIRM_ERROR, payload: error, error: true });

export const userLogout = () => ({ type: USER_LOGOUT });

// ================ Thunks ================ //

export const authInfo = () => (dispatch, getState, sdk) => {
  dispatch(authInfoRequest());
  return sdk
    .authInfo()
    .then((info) => dispatch(authInfoSuccess(info)))
    .catch((e) => {
      // Requesting auth info just reads the token from the token
      // store (i.e. cookies), and should not fail in normal
      // circumstances. If it fails, it's due to a programming
      // error. In that case we mark the operation done and dispatch
      // `null` success action that marks the user as unauthenticated.
      log.error(e, 'auth-info-failed');
      dispatch(authInfoSuccess(null));
    });
};

export const login = (username, password) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(loginRequest());

  // Note that the thunk does not reject when the login fails, it
  // just dispatches the login error action.
  return sdk
    .login({ username, password })
    .then(() => dispatch(loginSuccess()))
    .then(() => dispatch(fetchCurrentUser()))
    .catch((e) => dispatch(loginError(storableError(e))));
};

export const logout = (params) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(logoutRequest());
  const { cookieDomain: domain } = params;

  // Note that the thunk does not reject when the logout fails, it
  // just dispatches the logout error action.
  return sdk
    .logout()
    .then(() => {
      // NOTE: this cookie key needs to match what was sent in api/auth/loginWithIdp.js
      // with the sdk.loginWithIdp response.
      const CLIENT_ID = process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID;
      const cookieKey = `st-${CLIENT_ID}-token`;

      // Remove the access token cookie that was set during the loginWithIdp flow, if any.
      // Access token cookies set by ShareTribe's sdk for other flows are already
      // automatically removed by sdk.logout().
      const cookieParams = domain ? { domain } : {};
      Cookies.remove(cookieKey, cookieParams);

      // The order of the dispatched actions
      dispatch(logoutSuccess());
      dispatch(clearCurrentUser());
      log.clearUserId();
      dispatch(userLogout());
    })
    .catch((e) => dispatch(logoutError(storableError(e))));
};

export const signup = (params) => async (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupRequest());

  const { email, password, firstName, lastName, preferredLocale, ...rest } = params;

  const { shopId, initialSearchParams, treetId, primaryLocale } = getState().initial;

  let localeToUse = preferredLocale;

  if (!Object.values(Locale).includes(preferredLocale)) {
    log.error('preferredLocale is not a valid locale, setting locale to the shop primary locale', {
      preferredLocale,
      primaryLocale,
      email,
      firstName,
      lastName,
    });
    localeToUse = primaryLocale;
  }

  const createUserParams = isEmpty(rest)
    ? { email, password, firstName, lastName }
    : {
        email,
        password,
        firstName,
        lastName,
        protectedData: { ...rest },
        privateData: { preferredLocale: localeToUse },
      };

  const [response, responseError] = await handle(sdk.currentUser.create(createUserParams));
  if (responseError) {
    dispatch(signupError(storableError(responseError)));

    // Do not log 409 conflict errors to sentry. This likely means the user is trying to sign-up
    // with an email that already has a Treet account associated with it.
    if (responseError.status !== 409) {
      log.error(responseError, 'signup-failed', {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
      });
    }
    return null;
  }
  await dispatch(signupSuccess());

  // Create Postgres User for each Sharetribe User
  const newUser = denormalisedResponseEntities(response)?.[0];
  const postgresCreateUserInput = {
    email: params.email,
    firstName: params.firstName,
    lastName: params.lastName,
    sharetribeUserId: newUser?.id?.uuid,
    createdFromShopId: shopId,
    preferredLocale: localeToUse,
  };

  const [, postgresUserError] = await handle(
    apolloClient.mutate({
      mutation: CreateUserDocument,
      variables: {
        input: postgresCreateUserInput,
      },
    })
  );
  if (postgresUserError) {
    // Log error to sentry only without disrupting the user flow.
    // (TODO|TREET-3578): Once Users data is fully migrated off of Sharetribe, start throwing error if creating
    // PG User fails.
    log.error(postgresUserError, 'postgres-create-user-failed', {
      ...postgresCreateUserInput,
      sharetribeUser: newUser,
    });
  }

  // Corresponds to constants that indicate where in the UI the auth modal was triggered.
  const { authModalTrigger } = getState().Auth;
  // Track sign up event + add attribution to user.
  trackSignUp(newUser, authModalTrigger || initialSearchParams?.get('utm_campaign'));

  // We must login the user if signup succeeds since the API doesn't
  // do that automatically.
  try {
    await dispatch(login(email, password));

    // Publish customer event
    publishCustomerEvent({
      treetId,
      customerEmail: params.email,
      eventType: 'signed_up',
    });
  } catch (error) {
    log.error(error, 'login-after-signup-failed', {
      email,
      response,
    });
    throw error;
  }

  // Return Sharetribe User
  return newUser;
};

// Handles setting correct authentication state for an IDP auth.
// Does not handle the actual login/signup process -
// (see loginWithIdp.js)
export const idpAuthentication = (params) => async (dispatch) => {
  const { success, error } = params;
  dispatch(confirmRequest());

  if (success) {
    dispatch(confirmSuccess());
    return dispatch(fetchCurrentUser());
  }

  const errorObject = JSON.parse(error) || { message: 'Error authenticating. Please try again.' };
  dispatch(confirmError(errorObject));
  return null;
};
