import moment from 'moment';
import { ShopIntegrationType } from '../../types/apollo/generated/types.generated';

export enum AdminPageTab {
  Home = 'home',
  MarketplaceDashboard = 'marketplace-dashboard',
  TradeInDashboard = 'trade-in-dashboard',
  Integrations = 'integrations',
  Disbursements = 'disbursements',
  Reports = 'reports',
  InventoryUpload = 'inventory-upload',
  Feedback = 'give-feedback',
  SchedulePromotion = 'schedule-promotion',
  Billing = 'billing',
}

export enum IntegrationPane {
  Description = 'description',
  Settings = 'settings',
}

export enum ShopIntegrationTypePathId {
  LoyaltyLionRewards = 'loyalty-lion-rewards',
  FillogicReturns = 'fillogic-returns',
  GivexCredit = 'givex-credit',
  HappyreturnsReturns = 'happy-returns-returns',
  KlaviyoCustomerSegmentation = 'klaviyo-customer-segmentation',
  KlaviyoEmailCustomization = 'klaviyo-email-customization',
  LoopReturns = 'loop-returns',
  ShopifyCustomerSegmentation = 'shopify-customer-segmentation',
  YotpoRewards = 'yotpo-rewards',
  RiseaiCredit = 'rise-ai-credit',
}

export enum HomePageId {
  BrandDirectUpload = 'brand-direct-upload',
  MainSiteLink = 'main-site-link',
}

export enum ActionCategory {
  Inventory = 'INVENTORY',
  Promotion = 'PROMOTION',
}

export enum ActionImpact {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
}

export enum ActionTime {
  Low = '< 1 MINUTE',
  Medium = '5-10 MINUTES',
  High = '< 1 WEEK',
}

export const SHOP_INTEGRATION_TYPE_TO_PATH_ID: { [key in ShopIntegrationType]: string } = {
  [ShopIntegrationType.LoyaltylionRewards]: ShopIntegrationTypePathId.LoyaltyLionRewards,
  [ShopIntegrationType.FillogicReturns]: ShopIntegrationTypePathId.FillogicReturns,
  [ShopIntegrationType.GivexCredit]: ShopIntegrationTypePathId.GivexCredit,
  [ShopIntegrationType.HappyreturnsReturns]: ShopIntegrationTypePathId.HappyreturnsReturns,
  [ShopIntegrationType.KlaviyoCustomerSegmentation]:
    ShopIntegrationTypePathId.KlaviyoCustomerSegmentation,
  [ShopIntegrationType.KlaviyoEmailCustomization]:
    ShopIntegrationTypePathId.KlaviyoEmailCustomization,
  [ShopIntegrationType.LoopReturns]: ShopIntegrationTypePathId.LoopReturns,
  [ShopIntegrationType.ShopifyCustomerSegmentation]:
    ShopIntegrationTypePathId.ShopifyCustomerSegmentation,
  [ShopIntegrationType.YotpoRewards]: ShopIntegrationTypePathId.YotpoRewards,
  [ShopIntegrationType.RiseaiCredit]: ShopIntegrationTypePathId.RiseaiCredit,
};

export const RETOOL_EMBED_TABS = [AdminPageTab.MarketplaceDashboard, AdminPageTab.TradeInDashboard];

export const formatDisbursementDate = (date: Date, dateFormat?: string) =>
  moment.utc(date).format(dateFormat || 'YYYY-MM-DD');
