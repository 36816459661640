import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconHangTagFilledProps {
  className?: string;
  color?: string;
}

const IconHangTagFilled: React.FC<IconHangTagFilledProps> = (props) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_439_158" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 1L6 10V27H26V10L16 1ZM16 12C17.1046 12 18 11.1046 18 10C18 8.89543 17.1046 8 16 8C14.8954 8 14 8.89543 14 10C14 11.1046 14.8954 12 16 12Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 1L6 10V27H26V10L16 1ZM16 12C17.1046 12 18 11.1046 18 10C18 8.89543 17.1046 8 16 8C14.8954 8 14 8.89543 14 10C14 11.1046 14.8954 12 16 12Z"
        fill={fillColor}
      />
      <path
        d="M6 10L4.66207 8.51341L4 9.10927V10H6ZM16 1L17.3379 -0.486588L16 -1.69072L14.6621 -0.486588L16 1ZM6 27H4V29H6V27ZM26 27V29H28V27H26ZM26 10H28V9.10927L27.3379 8.51341L26 10ZM7.33793 11.4866L17.3379 2.48659L14.6621 -0.486588L4.66207 8.51341L7.33793 11.4866ZM8 27V10H4V27H8ZM26 25H6V29H26V25ZM24 10V27H28V10H24ZM14.6621 2.48659L24.6621 11.4866L27.3379 8.51341L17.3379 -0.486588L14.6621 2.48659ZM16 10V14C18.2091 14 20 12.2091 20 10H16ZM16 10H20C20 7.79086 18.2091 6 16 6V10ZM16 10V6C13.7909 6 12 7.79086 12 10H16ZM16 10H12C12 12.2091 13.7909 14 16 14V10Z"
        fill={fillColor}
        mask="url(#path-1-inside-1_439_158)"
      />
    </svg>
  );
};

export default IconHangTagFilled;
