/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */

/*
 * This component is a modified version of the TermsOfService component,
 * created as a one-off request for Represent.
 */

import React, { FC } from 'react';
import classNames from 'classnames';
import { Box } from '@material-ui/core';
import { TypographyWrapper } from '..';
import { TypographyFormat, TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import css from './RepresentTermsOfService.module.css';

interface RepresentTermsOfServiceProps {
  rootClassName?: string;
  className?: string;
}

const RepresentTermsOfService: FC<RepresentTermsOfServiceProps> = (
  props: RepresentTermsOfServiceProps
) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <Box className={classes}>
      <TypographyWrapper variant="body1">Last updated: 27 January 2025.</TypographyWrapper>

      <TypographyWrapper variant="body1">
        This site is for the sale and purchase of pre-loved Represent clothing to allow Represent
        customers to purchase and sell pre-loved Represent products, using a third party online
        platform called Treet.
      </TypographyWrapper>
      <TypographyWrapper variant="body1" weight={TypographyWeight.Bold}>
        Please note that this site (https://archive.representclo.com/) is operated by Treet, Inc.,
        and all transactions for the sale of Items processed through this site are handled by Treet,
        subject to Treet’s Terms of Use set out below. All personal data of Users of the Site is
        processed by Treet in accordance with Treet’s privacy policy.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">1. What is Treet?</TypographyWrapper>
      <TypographyWrapper variant="body1">
        Treet is an online platform, marketplace, and service allowing Users to buy and sell
        Represent products from each other in exchange for cash, or a gift card for the Represent
        site, at https://uk.representclo.com (
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ display: 'inline' }}
        >
          “Represent Site”
        </TypographyWrapper>
        ). Represent may also sell last season stock through the Treet site. References to “Treet”,
        “we”, “our” and “us” are to Treet, Inc., a Delaware corporation; and references to
        “Represent” or “Brand” are to Represent Clothing Limited, company number 09221485,
        registered office Unit 1 Northgate Close, Horwich, Bolton, United Kingdom BL6 6PQ. This site
        https://archive.representclo.com/ (
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ display: 'inline' }}
        >
          “Site”
        </TypographyWrapper>
        ) is operated by Treet, and is for Users resident in the United Kingdom. All sale
        transactions are processed and handled by Treet through this Site and are subject to these
        Terms of Use. All other defined terms are set out in section 19 below.
      </TypographyWrapper>
      <TypographyWrapper variant="body1">
        Sales of products are either between: (i) a private seller and the Buyer (a{' '}
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ display: 'inline' }}
        >
          Peer to Peer Item
        </TypographyWrapper>
        ); or (ii) Represent and the Buyer (a{' '}
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ display: 'inline' }}
        >
          Brand Direct Item
        </TypographyWrapper>
        ).
      </TypographyWrapper>
      <TypographyWrapper variant="body1">
        Represent shall have no involvement with, and shall not be a party to, any transaction or
        contract for the sale and purchase of Peer to Peer Item(s). Users acknowledge and accept
        that they are not purchasing Peer to Peer Items from Treet or Represent.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">2. Application & Enforceability</TypographyWrapper>
      <TypographyWrapper variant="body1">
        By accessing, viewing, or using Treet or any tools and services provided in connection with
        Treet, you understand and agree to be bound by all terms and conditions contained in these
        Terms of Use. If you object to anything in these Terms of Use, including the mandatory
        arbitration provision in Section 17 and class action waiver in Section 18, or otherwise do
        not understand or agree to be bound by these Terms of Use, do not use the Services or create
        an account.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">3. User Obligations</TypographyWrapper>
      <TypographyWrapper variant="body1">
        We grant you a limited, non-exclusive, non-transferable, and revocable license to use Our
        Services—subject to these Terms of Use and the following restrictions in particular:
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ display: 'inline' }}
        >
          You are responsible for your account.
        </TypographyWrapper>{' '}
        To use many of the Services on this Site, you will need to register and create an account
        with a username and password. You must provide us with accurate and complete information,
        and you must update your account information as needed to keep the information accurate and
        complete. You may not impersonate anyone else, choose a username that may offend someone, or
        violate any individual’s rights. You are solely responsible for maintaining the
        confidentiality of your account and for all activities associated with or occurring under
        your account. If you suspect or discover any unauthorized use of your account, you should
        notify Treet immediately by contacting us at support@treet.co. We are not responsible for
        any loss or damage arising from your failure to comply with the foregoing requirements or as
        a result of use of your account with or without your knowledge.
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ display: 'inline' }}
        >
          Terminating your account.
        </TypographyWrapper>{' '}
        You may submit a request to terminate your account by contacting us at support@treet.co.
        This will not automatically delete User Content. We may need to retain certain information
        as required by law or as necessary for Our business purposes. These Terms, including our
        rights to User Content, survive termination of an account.
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ display: 'inline' }}
        >
          Be truthful.
        </TypographyWrapper>{' '}
        Do not post information you know is false, misleading, or inaccurate. Do not do anything
        deceptive or fraudulent. Respect the rights of others. You may not threaten, abuse, harass,
        defame, or engage in behavior that is libelous, tortious, obscene, profane, or invasive of
        another’s privacy. Do not engage in activities that affect the functioning of the Site. You
        may not bypass any measures that We have put in place to secure Our Site or Services, take
        actions to gain unauthorized access to any system, data, passwords, or other Treet or User
        information or Services, reverse engineer or take apart any aspect of the Services to access
        any underlying information, or use any kind of software to “crawl” or “spider” any part of
        the Site.
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ display: 'inline' }}
        >
          Fees.
        </TypographyWrapper>{' '}
        There are no fees for creating an account on the Site. However, Sellers will be charged a
        platform fee when selling an Item through the Site (as set out in section 6 below).
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        Should you violate any of the Terms of Use, We reserve the right to terminate, suspend, or
        take any other action related to your account at Our sole discretion. You may not access Our
        Site or Services if We have prohibited you from doing so.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">4. Treet’s Role</TypographyWrapper>
      <TypographyWrapper variant="body1">
        While Treet may review and approve Posts before the Post is made available on the Site,
        Treet does not claim any ownership over the Items sold on the Site, and does not guarantee
        the quality, or verify the authenticity, of any Items sold on the Site. We may remove Posts
        and User Content that we consider, in our sole discretion, violates the Terms of Use.
        Removing User Content, terminating an account, or canceling transactions are not actions We
        take lightly, and We may take a while to investigate and determine if such actions are
        warranted. It is at Treet’s sole discretion whether to discuss the reasons for taking action
        against any User Content or account. We do not become involved in legal disputes between
        Users. Treet bears no liability, and has no obligation to investigate, participate, or
        become involved, in any dispute between Users, including between Buyers and Sellers.
      </TypographyWrapper>

      <Box pb={1}>
        <TypographyWrapper
          variant="h2"
          format={TypographyFormat.Underlined}
          weight={TypographyWeight.Bold}
        >
          Selling an Item
        </TypographyWrapper>
      </Box>

      <TypographyWrapper variant="h2">5. Seller Obligations</TypographyWrapper>
      <TypographyWrapper variant="body1">
        As a Seller on Treet, when you create a Post on the Site, you understand that you are
        entering into separate legal agreements with both Treet and with Buyers, and the following
        rules apply (in addition to all other Terms).
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        You agree that: (i) you own and have rights to sell the Item being posted on Treet; (ii)
        each Peer to Peer Item posted on Treet: was purchased by the Seller at Represent (whether in
        store or online); has not been altered or modified since purchase; and is genuine and not
        counterfeit.
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ display: 'inline' }}
        >
          Be responsive.
        </TypographyWrapper>{' '}
        Respond promptly and truthfully to all questions posed by Buyers and any questions or
        requests Treet makes. Make good faith efforts to fulfill the items associated with a Post in
        the timeframe set out in section 7 (Shipping) below. Immediately notify Treet if there are
        obstacles or delays.
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ display: 'inline' }}
        >
          Be truthful and transparent.
        </TypographyWrapper>{' '}
        Sellers should be ready, willing, and able to substantiate claims made in your Posts,
        including (without limitation) in respect of the condition, sizing, and all additional Post
        descriptions. If you are unable to substantiate claims, Treet may terminate your account,
        withhold funds, or other actions to enforce its rights under this Agreement and applicable
        law.
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ display: 'inline' }}
        >
          Respect privacy.
        </TypographyWrapper>{' '}
        When you use the Services to sell Items via the Site, you may receive information about
        other Users, including personally identifiable information (“PII”), such as names, e-mail
        addresses, and physical addresses. This information is provided to you purely for the
        purpose of sending the Items to the relevant Buyer, and may not be used or disclosed for
        other purposes.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">6. Platform Fees</TypographyWrapper>
      <TypographyWrapper variant="body1">
        In respect of the sale of Items via the Site, the Seller shall be charged a platform fee
        based on the Selling Price of the Peer to Peer Item and this will be deducted prior to funds
        being sent to the Seller. Details of the platform fees for selling Items via the Site, and
        the options for receiving funds, are set out{' '}
        <a href="https://archive.representclo.com/about/seller?no_scroll=true%20%5Cl%20selling-fees">
          HERE
        </a>
        , in the Seller FAQs. Treet reserves the right to change the fees at any time and such
        changes will be reflected on this Site.
      </TypographyWrapper>
      <TypographyWrapper variant="body1">
        Please note that you will only receive the funds from the sale transaction once your Item
        has been delivered and verified by the Buyer. The transaction will be automatically verified
        72 hours after delivery if the Buyer has not raised any issues with Treet regarding the
        Item.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">7. Shipping</TypographyWrapper>
      <TypographyWrapper variant="body1">
        If a Seller sells an Item on Treet, the Seller will be provided a prepaid shipping label and
        must ship their Items to the Buyer within seven days of the purchase date. If the Seller
        does not ship the Item within seven days, Treet reserves the right to cancel the transaction
        and provide a full refund to the Buyer. If a Seller continuously misses the Shipping window,
        Treet may, in its sole discretion, terminate the Seller account. Sellers must only use the
        provided prepaid shipping label to ship their purchased Item and is wholly responsible for
        abiding by the conditions of the shipping carrier.
      </TypographyWrapper>

      <Box pb={1}>
        <TypographyWrapper
          variant="h2"
          format={TypographyFormat.Underlined}
          weight={TypographyWeight.Bold}
        >
          Buying an Item
        </TypographyWrapper>
      </Box>

      <TypographyWrapper variant="h2">8. Delivery</TypographyWrapper>
      <TypographyWrapper variant="body1">
        When the Buyer purchases an Item on Treet, a contract is entered into between the Buyer and
        the Seller. The Seller will be required to ship their Items to the Buyer within seven days
        of the purchase date. If the Seller does not ship the Item within seven days, Treet may
        cancel the transaction and provide a full refund to the Buyer.
      </TypographyWrapper>
      <TypographyWrapper variant="body1">
        Delivery of the Item shall be complete when it is delivered to the address given by the
        Buyer at the time of purchasing the Item and the Item (and the risk of loss) will be the
        Buyer’s sole responsibility from the time of delivery.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">9. Refunds & Returns</TypographyWrapper>
      <TypographyWrapper variant="body1" format={TypographyFormat.Underlined}>
        Peer to Peer Items
      </TypographyWrapper>
      <TypographyWrapper variant="body1">
        There is no general right of return in respect of Peer to Peer Items sold on Treet.
      </TypographyWrapper>
      <TypographyWrapper variant="body1">
        However, all Peer to Peer Items are covered by{' '}
        <TypographyWrapper
          variant="body1"
          weight={TypographyWeight.Bold}
          typographyOverrides={{ display: 'inline' }}
        >
          Treet Protection
        </TypographyWrapper>
        . Buyers can claim a refund using Treet Protection if a Peer to Peer Item is:
      </TypographyWrapper>
      <ul className={css.list}>
        <li>
          <TypographyWrapper variant="body1">
            <TypographyWrapper
              variant="body1"
              format={TypographyFormat.Underlined}
              typographyOverrides={{ display: 'inline' }}
            >
              Misrepresented
            </TypographyWrapper>
            , meaning there is a significant difference between the item you have received and the
            description or photos of the Item in the Seller’s Post;
          </TypographyWrapper>
        </li>
        <li>
          <TypographyWrapper variant="body1">
            <TypographyWrapper
              variant="body1"
              format={TypographyFormat.Underlined}
              typographyOverrides={{ display: 'inline' }}
            >
              Incorrect
            </TypographyWrapper>
            , meaning the item received is not the Item set out in the Seller’s Post;
          </TypographyWrapper>
        </li>
        <li>
          <TypographyWrapper variant="body1">
            <TypographyWrapper
              variant="body1"
              format={TypographyFormat.Underlined}
              typographyOverrides={{ display: 'inline' }}
            >
              Inauthentic
            </TypographyWrapper>
            , meaning the item received is not an authentic Brand product; or
          </TypographyWrapper>
        </li>
        <li>
          <TypographyWrapper variant="body1">
            <TypographyWrapper
              variant="body1"
              format={TypographyFormat.Underlined}
              typographyOverrides={{ display: 'inline' }}
            >
              Lost
            </TypographyWrapper>{' '}
            during transit, where the courier has notified the Buyer that the Item has been lost.
          </TypographyWrapper>
        </li>
      </ul>
      <TypographyWrapper variant="body1">
        If there is an issue with a Peer to Peer Item received by the Buyer, the Buyer must file a
        Misrepresentation Claim with Treet within 72 hours of receiving the Item or being notified
        by the courier the Item has been lost in transit. Please click{' '}
        <a href="https://archive.representclo.com/about/protection">HERE</a> for details on how to
        submit a Misrepresentation Claim to request a return. Treet will review the Buyer’s
        Misrepresentation Claim and confirm if any additional information or photos are required. If
        such claim is accepted, Treet will provide the Buyer with a prepaid return shipping label
        for the Buyer to return the Item within 72 hours. Once the Item is detected as being in
        transit, and provided that the Item is shipped within the 72 hour window, Treet will issue a
        refund for the cost of purchasing the Item by the method the Buyer used for payment.
      </TypographyWrapper>
      <TypographyWrapper variant="body1">
        If more than 72 hours have passed since the Peer-to-Peer Item has been received by Buyer, or
        from when the Buyer has been notified by the courier the Item has been lost in transit,
        Treet will automatically mark the Buyer’s purchase of the Item as completed and the Buyer
        will not be eligible for a refund under Treet Protection.
      </TypographyWrapper>
      <TypographyWrapper variant="body1">
        The Buyer will not receive a refund under Treet Protection in the event that:{' '}
      </TypographyWrapper>
      <ul className={css.list}>
        <li>
          <TypographyWrapper variant="body1">
            The Buyer has not raised a Misrepresentation Claim within 72 hours of receiving the
            relevant Item or from when the Buyer has been notified by the courier that the Item has
            been lost in transit;
          </TypographyWrapper>
        </li>
        <li>
          <TypographyWrapper variant="body1">
            The Buyer has not provided Treet with any requested additional information or photos
            within any timescales required by Treet;
          </TypographyWrapper>
        </li>
        <li>
          <TypographyWrapper variant="body1">
            Treet determines that the Claim is not covered by Treet Protection;
          </TypographyWrapper>
        </li>
        <li>
          <TypographyWrapper variant="body1">
            The Buyer has not returned the Item within 72 hours of receiving the return shipping
            label from Treet; or
          </TypographyWrapper>
        </li>
        <li>
          <TypographyWrapper variant="body1">
            Treet determines that the Buyer has used or altered the relevant Item before returning
            it to the Seller.
          </TypographyWrapper>
        </li>
      </ul>

      <TypographyWrapper variant="body1" format={TypographyFormat.Underlined}>
        Brand Direct Items
      </TypographyWrapper>

      <TypographyWrapper variant="body1" format={TypographyFormat.Underlined}>
        Goodwill returns policy
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        The Brand offers a 14 day returns policy on Brand Direct Items only. Items must be in an
        unused, re-saleable condition, in the original packaging with all the tags intact. Please
        click <a href="https://archive.representclo.com/about/protection">HERE</a> on how to return
        Items. Returns requests should be submitted via Your Purchases page within the 14 days
        returns period. Once you have logged your returns request, and such request is made in
        accordance with the returns policy, you will receive a return shipping label. The cost of
        returning the Item is £5.99, which will be deducted from your refund, unless the item is
        faulty.
      </TypographyWrapper>

      <TypographyWrapper variant="body1" format={TypographyFormat.Underlined}>
        Cancellation rights – Brand Direct Items only
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        These rights only apply to Buyers in the UK. Buyers in the UK have a legal right to change
        their mind and cancel their contract, in relation to Brand Direct Items only, within 14 days
        of delivery of the Brand Direct Item. If the Buyer wishes to exercise their right to cancel
        their contract, the Buyer must:
      </TypographyWrapper>

      <ul className={css.list}>
        <li>
          <TypographyWrapper variant="body1">
            notify Treet within 14 days of the date of delivery of the Item. You can do this by
            contacting Treet Customer Service via the chat bubble on the bottom right hand corner of
            the Archive site;
          </TypographyWrapper>
        </li>
        <li>
          <TypographyWrapper variant="body1">
            Treet will provide the Buyer with a shipping return label for the Buyer to return the
            Brand Direct Item to the Brand. The cost of returning the Item is £5.99, which will be
            deducted from your refund. The Buyer must return the Brand Direct Item in an unused,
            re-saleable condition, in the original packaging with all the tags intact. The amount of
            the refund may be reduced to reflect any reduction in the value of the Brand Direct Item
            which has been caused by the Buyer handling the Brand Direct Item in a way which would
            not be permitted in a shop.
          </TypographyWrapper>
        </li>
      </ul>

      <TypographyWrapper variant="body1">
        Treet shall arrange a refund to the Buyer within 14 days of the Brand receiving the returned
        Item. Treet will refund the Buyer by the method the Buyer used for payment.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">10. Payment and Taxes</TypographyWrapper>
      <TypographyWrapper variant="body1">
        The Seller determines the price of the Item, which shall be set out in the Seller’s Post.
        Shipping costs will be added to the total price for the Item at checkout.
      </TypographyWrapper>
      <TypographyWrapper variant="body1">
        The accepted payment methods are set out during the checkout process on Treet.
      </TypographyWrapper>
      <TypographyWrapper variant="body1">
        Applicable taxes may be collected upon purchasing Items on Treet based on the jurisdiction
        in which the purchase is made.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">11. Prohibited Items</TypographyWrapper>
      <TypographyWrapper variant="body1">
        Any listing or sale of an Item that is illegal to sell online under any applicable statute,
        ordinance, law, or regulation, is strictly prohibited by Treet. Items must also abide by the
        relevant shipping carrier’s Domestic Shipping Prohibitions & Restrictions. While Items sold
        on Treet may be used, no item can be in poor condition or significantly damaged; all Items
        must be clean and in good condition. Treet also prohibits posting any fake, counterfeit, or
        otherwise fraudulent Items. Treet or the Brand reserves the right to determine whether Items
        can or cannot be posted on the Site, so long as the Items are not prohibited by this
        Section.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">
        12. Store Credit, Discount Codes, & Gift Cards
      </TypographyWrapper>
      <TypographyWrapper variant="body1">
        Treet may offer Sellers the ability to redeem funds as brand credit with Represent in the
        form of a gift card redeemable on the Represent Site. Gift cards are subject applicable
        terms and conditions set out on the Represent Site. The policy for using the gift cards is
        wholly determined by Represent, and Treet is not responsible for any items purchased using
        the gift cards.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">13. Your Intellectual Property</TypographyWrapper>
      <TypographyWrapper variant="body1">
        Your User Content remains your property. When you submit User Content to the Site or via the
        Services, you agree to the following terms:
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        You allow us to use your User Content. You grant us a worldwide, non-exclusive, perpetual,
        irrevocable, royalty-free, sublicensable, and transferable right to use, exercise,
        commercialize, and exploit the copyright, publicity, trademark, and database rights with
        respect to your User Content. We can make changes to or delete your User Content. You grant
        us the right to make changes, edits, modifications, translations, formatting, or delete your
        User Content. You have all legal rights to your User Content. You represent and warrant
        that: (1) you have all licenses, rights, consents, and permissions necessary to grant the
        rights set forth in these Terms to Treet with respect to your User Content; (2) your User
        Content does not and will not infringe any third party’s intellectual property rights,
        proprietary rights, privacy rights, confidentiality, rights of publicity or otherwise
        violate these Terms or applicable law; and (3) Treet does not need to obtain any licenses,
        rights, consents, or permissions from, or make any payments to, any third party for any use
        of your User Content, or have any liability to a User or any other party as a result of Our
        use or exploitation of your User Content.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">
        14. Treet’s Rights Concerning User Accounts; Termination
      </TypographyWrapper>
      <TypographyWrapper variant="body1">
        We reserve the right to monitor, terminate, suspend, or delete any User Account at any time
        in Our sole discretion. We have no obligation to comment on any reasons for account
        monitoring, termination, suspension, or deletion. We have no liability to you or third
        parties for any use of the Services associated with your account. Upon suspension or
        termination of your account, you must cease all use of the Services and User Content. Your
        representations and warranties shall survive termination. Treet reserves the right to
        suspend or terminate a Post if it does not comply with these Terms of Use or in its sole
        discretion. In the event of termination, Treet may refund any and all funds received from a
        Buyer and may seek reimbursement from the Seller for any funds the Seller has already
        received from Treet. Treet may also avail itself of any of the remedies provided for in this
        Agreement and any remedies available to enforce its rights under this Agreement or
        applicable law.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">15. Payment Services</TypographyWrapper>
      <TypographyWrapper variant="body1">
        Credit card payment processing services for Sellers on Treet are provided by Stripe, Inc.
        (“Stripe”) and are subject to the Stripe Connected Account Agreement, which includes the
        Stripe Terms of Service (collectively, the “Stripe Services Agreement”). As a Seller, you
        agree to be bound by the Stripe Services Agreement, as may be modified by Stripe from time
        to time. As a condition to Treet enabling credit card payment processing services through
        Stripe, you agree to provide Treet accurate and complete information about yourself and you
        authorize Treet to share any such information with Stripe, as well as transaction
        information related to your use of the Stripe payment processing services. We are not
        responsible for the performance of any third-party credit card processing or third-party
        payment services.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">16. Disclaimer of Liability</TypographyWrapper>
      <TypographyWrapper variant="body1">
        Use the Services at your own risk. We make no representations about the quality, safety,
        morality or legality of any Post or Item or the truth or accuracy of User Content posted on
        the Services. We do not guarantee the fulfillment or the quality of any Items. We do not
        guarantee in any manner the reliability, validity, accuracy or truthfulness of such User
        Content. Treet is not liable for any damages or losses related to your use of the Services.
        You release us from all claims. When you use the Services, you release Treet from claims,
        damages, and demands of every kind - known and unknown, suspected or unsuspected, disclosed
        or undisclosed - arising out of or related to the Services. All Content that you access or
        use through the Services is entirely at your own risk and you solely are responsible for any
        resulting damage or loss to any party.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">17. Arbitration</TypographyWrapper>
      <TypographyWrapper variant="body1">
        Any controversy, claim, suit, injury, harm, loss, or damage arising from or in any way
        related to the use or provision of the Service or these Terms, shall only be resolved by
        binding arbitration and conducted in accordance with these Terms of Use and the Commercial
        Arbitration Rules and Arbitration Procedures of the American Arbitration Association (“AAA”)
        then in effect, which can be obtained at any AAA office, at www.adr.org, or by calling the
        AAA at 1-800-778-7879. If these is any inconsistency between the AAA Rules and this
        arbitration provision, the terms of the arbitration provision will control unless the
        arbitrator determines that the application of the inconsistent terms would result in a
        fundamentally unfair arbitration. This arbitration provision will be construes and enforced
        in accordance with the Federal Arbitration Act, 9 U.S.C. §1 et seq.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">18. Class Action Waiver</TypographyWrapper>
      <TypographyWrapper variant="body1">
        No Class or Representative Actions. In the event that a tribunal or arbitrator of competent
        jurisdiction determines that the arbitration provision is unenforceable, you agree that the
        exclusive forum and/or venue for any controversy, claim, suit, injury, harm, loss or damage
        arising from or in any way related to the use, inability to use or provision of the Service
        or these Terms of Use shall be any California State Court or Federal Court sitting in San
        Francisco County and you hereby submit to the exclusive jurisdiction of those courts for
        purposes of any such proceeding.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">19. Definitions</TypographyWrapper>
      <TypographyWrapper variant="body1">
        “Services” refers to the Site, mobile applications or connected applications, other
        offerings and services provided on the Site; “User”, “You”, or “ Your” refers to Buyers,
        Sellers or any other visitor to the Site or Users of the Services, either individually or
        collectively; “Items” are the individual articles or products the Seller is listing on the
        Site for sale; “Site” refers to this site, https://archive.representclo.com/; “Terms” or
        “Terms of Use” refers these Terms of Use, Additional Policies, and Treet’s Privacy Policy
        which shall apply to the use of this Site; “User Content” refers to Content uploaded,
        transmitted or posted to the Services by a User, including User Content in a Post; “Posts”
        are Sellers’ Item listings through the Services; “Buyers” refers to those Users purchasing
        Items from Sellers; “Sellers” are those Users who sell their Items through the Site and
        Services; “Selling Price” refers to the price an Item is purchased for through the Site;
        “PII” refers to personally-identifiable information, as that term is defined under all
        applicable laws; “business days” means Monday to Friday, excluding bank holidays.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">20. Full Agreement Between You and Us</TypographyWrapper>
      <TypographyWrapper variant="body1">
        These Terms are the entire agreement between You and Treet with respect to the Services.
        They supersede all other communications and proposals (whether oral, written, or electronic)
        between you and Treet with respect to the Services and govern our relationship. If any
        provision of these Terms are deemed invalid by a court of competent jurisdiction, the
        invalidity of such provision shall not affect the validity of the remaining provisions of
        this Agreement, which shall remain in full force and effect. Treet’s failure to assert any
        right or provision under this Agreement shall not constitute a waiver of such right or
        provision.
      </TypographyWrapper>

      <TypographyWrapper variant="body1">
        You understand and agree the Treet may revise, modify, and/or supplement these terms of Use
        (the “Revised Terms of Use”) at any time. Any Revised Terms of Use will be effective
        immediately after being posted here with a date of when the revision has occurred.
      </TypographyWrapper>

      <TypographyWrapper variant="h2">21. Miscellaneous</TypographyWrapper>
      <TypographyWrapper variant="body1">
        We may modify or discontinue the Services at any time, in our sole discretion. You agree
        that, except as otherwise expressly provided in these Terms, there shall be no third-party
        beneficiaries to these Terms. No waiver of any provision of these Terms shall be deemed a
        further or continuing waiver of such term or any other term, and Treet’s failure to assert
        any right or provision under these Terms shall not constitute a waiver of such right or
        provision. You agree that regardless of any statute or law to the contrary, any claim
        arising out of or related to the Services must commence within one (1) year after the cause
        of action accrues. Otherwise, such cause of action is permanently barred.
      </TypographyWrapper>
    </Box>
  );
};

export default RepresentTermsOfService;
