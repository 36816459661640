import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import Cookies from 'js-cookie';
import {
  Error,
  FormattedMessage,
  IconTreetLogo,
  LinkTabNavHorizontal,
  ModalTitle,
  TypographyWrapper,
} from '..';
import {
  authenticationInProgress,
  confirmRequest,
  idpAuthentication,
  login,
  signup,
} from '../../ducks/Auth.duck';
import { LoginForm, SignupForm } from '../../forms';
import { isSignupEmailTakenError } from '../../util/errors';
import { injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import {
  logBeginGoogleLogin,
  logBeginGoogleSignup,
  logLogin,
  logSignUp,
} from '../../analytics/pixelAnalytics';
import { SocialLoginButton } from '../Button/Button';
import { GoogleLogo } from './SocialLoginLogos';
import { pathByRouteName } from '../../util/routes';
import { useShopConfig } from '../../hooks/shopConfig';
import { POWERED_BY_TREET_TYPES } from '../../shopConfig/config';
import { apiBaseUrl } from '../../util/envHelpers';
import { useRouteConfiguration } from '../../hooks/useRouteConfiguration';
import AppContext from '../../context/AppContext';
import css from './AuthenticationForm.module.css';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { getUrlSearchParams } from '../../util/urlHelpers';
import { TREET_AUTH_EVENT } from '../../containers/AuthenticationRedirectPage/AuthenticationRedirectPage';
import { isFbOrIgBrowser } from '../../util/userAgent';
import { getDarkerBrandCssBackgroundColor } from '../../util/colors/colors';
import { TypographyFormat, TypographyWeight } from '../TypographyWrapper/TypographyWrapper';
import { useYotpo } from '../../hooks/useYotpo';
import { getBrandCountryConfigFromShopConfig } from '../../hooks/useCountryConfig';

const KEY_CODE_ENTER = 13;
const DEFAULT_LANGUAGE = 'EN';

const AuthError = (props) => {
  const { children } = props;

  return (
    <Box mt={3}>
      <Error>{children}</Error>
    </Box>
  );
};

const AuthHeader = forwardRef((props, ref) => {
  const { isLogin, signupTabProperties, loginTabProperties, bannerText, hasError } = props;
  const { css: brandCss, shopName } = useShopConfig();
  const tabs = [
    {
      text: (
        <ModalTitle className={css.tab}>
          <FormattedMessage id="AuthenticationPage.signupLinkText" />
        </ModalTitle>
      ),
      selected: !isLogin,
      ...signupTabProperties,
    },
    {
      text: (
        <ModalTitle className={css.tab}>
          <FormattedMessage id="AuthenticationPage.loginLinkText" />
        </ModalTitle>
      ),
      selected: isLogin,
      ...loginTabProperties,
    },
  ];

  const bannerEl =
    // Errors may be due to confusion with the mixup between the treet account
    // vs. the brand account so we want to prioritize treet account
    // clarification over the banner text
    bannerText && !hasError ? (
      <TypographyWrapper weight={TypographyWeight.Bold} display="inline" variant="body2">
        {bannerText}
      </TypographyWrapper>
    ) : (
      <>
        <Box display="inline-block" height="24px" mb={1}>
          <TypographyWrapper
            weight={TypographyWeight.Bold}
            variant="body1"
            typographyOverrides={{ display: 'inline' }}
          >
            {isLogin ? 'Log in' : 'Sign up'}&nbsp;with&nbsp;
          </TypographyWrapper>
          <IconTreetLogo className={css.treetLogo} color={brandCss?.body2?.color} />
        </Box>
        <TypographyWrapper
          display="inline"
          variant="body2"
          // Overriding font size because we don't have a variant for tiny text
          typographyOverrides={{ style: { fontSize: '10px' } }}
        >
          {`(Separate from your ${shopName} account)`}
        </TypographyWrapper>
      </>
    );

  return (
    <>
      <Box
        className={css.banner}
        sx={{ backgroundColor: getDarkerBrandCssBackgroundColor(brandCss) }}
        position="absolute"
        top="0"
        left="0"
        m="0"
        p={3}
        width="100%"
        ref={ref}
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          backgroundColor={getDarkerBrandCssBackgroundColor(brandCss)}
        >
          {bannerEl}
        </Box>
      </Box>
      <Box mt={10}>
        <LinkTabNavHorizontal className={css.tabs} tabs={tabs} />
      </Box>
    </>
  );
});

const IdpAuthErrorMessage = (props) => {
  // Handle auth errors that come from idp re-direct separately from other auth errors,
  // in order to ensure correct css module loading.
  const { authError, idp } = props;

  // Due to some extremely weird css module loading behavior upon idp auth re-direct,
  // the following logic should remain a 1 line nested ternary expression. Otherwise, if it's
  // split up into multiple if/else statements, the css class will not be picked up :(
  // If anyone actually figures out why / a better fix pls share :(
  // eslint-disable-next-line no-nested-ternary
  return authError && authError.status === 409 ? (
    // 409 Conflict error; the user likely already has a non-idp account.
    <AuthError>
      <FormattedMessage
        id="AuthenticationPage.confirmSignupFailedEmailAlreadyTaken"
        values={{ idp }}
      />
    </AuthError>
  ) : authError ? (
    <AuthError>{authError.message}</AuthError>
  ) : null;
};

const AuthErrorMessage = (props) => {
  const { isLogin, signupError, loginError } = props;
  const hasLoginError = isLogin && loginError;
  const hasSignupError = !isLogin && signupError;

  if (hasLoginError) {
    return (
      <AuthError>
        <FormattedMessage id="AuthenticationPage.loginFailed" />
      </AuthError>
    );
  }

  if (hasSignupError) {
    return (
      <AuthError>
        {isSignupEmailTakenError(signupError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </AuthError>
    );
  }

  return null;
};

const SocialLoginButtons = (props) => {
  const { isLogin, authWithGoogle, termsLink, privacyLink } = props;

  const { css: brandCss } = useShopConfig();

  const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const googleButtonText = isLogin ? (
    <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
  ) : (
    <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
  );

  const privacyAndTermsText = !isLogin ? (
    <Box className={css.termsWrapper}>
      <span className={css.termsText}>
        <TypographyWrapper variant="body1">
          By clicking Sign Up with Google, you agree to our {termsLink} and {privacyLink}.
        </TypographyWrapper>
      </span>
    </Box>
  ) : null;
  return showGoogleLogin ? (
    <div>
      <Box my={3}>
        <SocialLoginButton onClick={authWithGoogle} className={css.socialButton}>
          <Box px={2}>{GoogleLogo}</Box>
          <TypographyWrapper variant="body1">{googleButtonText}</TypographyWrapper>
          {/* Dummy node to evenly position the text */}
          <Box px={2} visibility="hidden">
            {GoogleLogo}
          </Box>
        </SocialLoginButton>
      </Box>
      {privacyAndTermsText}
      <div className={css.socialButtonsOr}>
        <Box className={css.socialButtonsOrText} bgcolor={brandCss?.backgroundColor || 'white'}>
          <TypographyWrapper variant="body2">
            <FormattedMessage id="AuthenticationPage.or" />
          </TypographyWrapper>
        </Box>
      </div>
    </div>
  ) : null;
};

export const AuthenticationFormComponent = (props) => {
  const {
    authInProgress,
    loginError,
    signupError,
    submitLogin,
    confirmError,
    submitSignup,
    tab,
    bannerText,
    from,
    handleOpenTermsOfService,
    handleOpenPrivacyPolicy,
    loginTabProperties,
    signupTabProperties,
    className,
    onSuccess,
    idpAuthRequest,
    handleIdpAuth,
  } = props;

  const routes = useRouteConfiguration();
  const { canonicalRootUrl: shopUrl, treetId } = useContext(AppContext);

  const shopConfig = useShopConfig();
  const { shopName, treetShopName, images, poweredByTreetType } = shopConfig;
  const { countryCode } = getBrandCountryConfigFromShopConfig(shopConfig);

  const { doesBrandUseLoyaltyPoints } = useYotpo();

  const { isBrandDirectOnly } = useEnabledCustomerExperiences();

  const [isIdpAuthActive, setIsIdpAuthActive] = useState(false);

  const [currentLanguage, setCurrentLanguage] = useState(DEFAULT_LANGUAGE);

  const authErrorViaCookie = Cookies.get('st-autherror')
    ? JSON.parse(Cookies.get('st-autherror').replace('j:', ''))
    : null;

  const authErrorSearchParamValue = getUrlSearchParams().get('authError');
  const authErrorViaParams =
    authErrorSearchParamValue && JSON.parse(decodeURI(authErrorSearchParamValue));

  const authError = authErrorViaCookie || authErrorViaParams || confirmError;
  const [authPopupError, setAuthPopupError] = useState(undefined);

  useEffect(() => {
    // Remove the autherror cookie / params once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    if (authError) {
      Cookies.remove('st-autherror');
      if (typeof window !== 'undefined') {
        window.history.replaceState(null, null, window.location.pathname);
      }
    }
  }, []);

  const topRef = useRef();
  useEffect(() => {
    if (signupError || loginError || confirmError) {
      // Focus to the top of the form when errors are detected so that they are visible.
      topRef.current.scrollIntoView();
    }
  }, [signupError, loginError, confirmError]);

  const treetLogoV2 = images?.treetShopLogo?.url;
  const logoV2 = images?.shopLogo?.url;
  const shopLogoUrl = treetLogoV2 || logoV2;
  const shouldShowPoweredByTreet = poweredByTreetType === POWERED_BY_TREET_TYPES.ICON;

  const isLogin = tab === 'login';

  // NOTE: Is this cookie still being used?
  const authInfo = Cookies.get('st-authinfo') // JSON dict containing email, name, and idp info
    ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
    : null;

  const authInfoFrom = authInfo?.from;
  const authFormFrom = from || authInfoFrom || '/';
  const rawIdp = authInfo?.idpId || authError?.idpId;
  const idp = rawIdp?.replace(/^./, (str) => str.toUpperCase());

  useEffect(() => {
    const handleGoogleAuthEvent = (event) => {
      handleIdpAuth(event.detail).then((response) => {
        if (response) {
          if (isLogin) {
            logLogin();
          } else {
            logSignUp();
          }
          onSuccess();

          // Redirect if on the login or signup page, otherwise don't redirect.
          if (['/login', '/signup'].includes(window.location.pathname) && authFormFrom) {
            window.location.href = authFormFrom;
          }
        }
      });

      setIsIdpAuthActive(false);
    };

    if (typeof window !== 'undefined' && isIdpAuthActive) {
      // Remove any existing event listeners.
      window.removeEventListener(TREET_AUTH_EVENT, handleGoogleAuthEvent);

      // Add event listener. Event fired from AuthPageRedirect in routeConfiguration.js.
      window.addEventListener(TREET_AUTH_EVENT, handleGoogleAuthEvent, {
        once: true,
      });
    }

    return () => {
      // Remove any event listeners on unmount.
      window.removeEventListener(TREET_AUTH_EVENT, handleGoogleAuthEvent);
    };
  }, [isIdpAuthActive]);

  // Use the WeGlot toggle to set the current language if there is language translation
  useEffect(() => {
    if (typeof window !== 'undefined' && window.Weglot) {
      const initializeWeglot = async () => {
        const language = (await window.Weglot.getCurrentLang()).toUpperCase();
        setCurrentLanguage(language);
      };
      initializeWeglot();

      const handleLanguageChange = (newLang) => {
        setCurrentLanguage(newLang.toUpperCase());
      };

      window.Weglot.on('languageChanged', handleLanguageChange);

      return () => window.Weglot.off('languageChanged', handleLanguageChange);
    }
    return () => {};
  }, []);

  const preferredLocale = `${currentLanguage}_${countryCode}`;

  const handleSubmitSignup = (values) => {
    const { fname, lname, ...rest } = values;

    const params = {
      firstName: fname.trim(),
      lastName: lname.trim(),
      // Store info about the shop the account was created from. This data is used in Sharetribe's
      // email templates.
      accountCreatedFromShopInfo: {
        shopName,
        shopUrl,
        treetShopName,
        isBrandDirectOnly,
        shopLogoUrl,
        shouldShowPoweredByTreet,
      },
      preferredLocale,
      ...rest,
    };
    submitSignup(params).then((resp) => {
      // If the current user is returned, that means login/signup was successful
      if (resp && resp.id) {
        logSignUp();
        onSuccess();
      }
    });
  };

  const getRouteParams = () => {
    const defaultReturn = pathByRouteName('LandingPage', routes);
    const url = new URL(window.location.origin);

    return {
      // Route where the user should be returned after authentication
      // This is used e.g. with EditListingPage and ListingPage
      ...(authFormFrom && { from: authFormFrom }),
      // Default route where user is returned after successful authentication
      ...(defaultReturn && { defaultReturn }),
      // Send host because otherwise we lose this context in auth callback re-directing
      host: url.host,
      // Send login vs signup info
      isLogin,
      shopUrl,
      // Send shop information to attach to user
      shopName,
      treetShopName,
      isBrandDirectOnly,
      shopLogoUrl,
      shouldShowPoweredByTreet,
      treetId,
    };
  };

  const authWithGoogle = () => {
    const unsupportedBrowserError =
      'Unable to launch login with Google for this browser. Please open this page in another browser and try again.';

    // Google blocks auth via embedded browsers.
    if (isFbOrIgBrowser()) {
      setAuthPopupError({
        message: unsupportedBrowserError,
      });

      return;
    }

    setIsIdpAuthActive(true);
    idpAuthRequest();

    if (isLogin) {
      logBeginGoogleLogin();
    } else {
      logBeginGoogleSignup();
    }

    if (typeof window !== 'undefined') {
      // Define popup properties.
      const width = 500;
      const height = 600;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2.5;

      // Open first with Treet domain to ensure correct referrer,
      // then change to google auth url after open.
      const authWindow = window.open(
        window.location.href,
        'GoogleAuthentication',
        `toolbar=no,menubar=no,width=${width},height=${height},left=${left},top=${top}`
      );

      // Some embedded browsers block popups. Google Auth does not
      // play well with embedded browsers anyway, so ask user to use new browser.
      if (!authWindow) {
        setAuthPopupError({
          message: unsupportedBrowserError,
        });
      }

      const baseUrl = apiBaseUrl();
      const routeParams = new URLSearchParams(getRouteParams());
      const googleAuthUrl = `${baseUrl}/api/auth/google?${routeParams.toString()}`;

      authWindow.location.href = googleAuthUrl;
    }
  };

  const handleTermsKeyUp = (e) => {
    // Allow click action with keyboard like with normal links
    if (e.keyCode === KEY_CODE_ENTER) {
      handleOpenTermsOfService();
    }
  };

  const handlePrivacyKeyUp = (e) => {
    // Allow click action with keyboard like with normal links
    if (e.keyCode === KEY_CODE_ENTER) {
      handleOpenPrivacyPolicy();
    }
  };

  const termsLink = (
    <span
      className={css.termsLink}
      onClick={handleOpenTermsOfService}
      role="button"
      tabIndex="0"
      onKeyUp={handleTermsKeyUp}
    >
      <TypographyWrapper
        variant="body1"
        format={TypographyFormat.Underlined}
        typographyOverrides={{ display: 'inline' }}
      >
        <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
      </TypographyWrapper>
    </span>
  );

  const privacyLink = (
    <span
      className={css.termsLink}
      onClick={handleOpenPrivacyPolicy}
      role="button"
      tabIndex="0"
      onKeyUp={handlePrivacyKeyUp}
    >
      <TypographyWrapper
        variant="body1"
        format={TypographyFormat.Underlined}
        typographyOverrides={{ display: 'inline' }}
      >
        <FormattedMessage id="SignupForm.privacyPolicyLinkText" />
      </TypographyWrapper>
    </span>
  );

  return (
    <Box className={className}>
      <AuthHeader
        isLogin={isLogin}
        signupTabProperties={signupTabProperties}
        loginTabProperties={loginTabProperties}
        bannerText={bannerText}
        hasError={signupError || loginError}
        idp={idp}
        ref={topRef}
      />
      <IdpAuthErrorMessage authError={authError || authPopupError} idp={idp} />
      <AuthErrorMessage isLogin={isLogin} signupError={signupError} loginError={loginError} />
      <SocialLoginButtons
        isLogin={isLogin}
        authWithGoogle={authWithGoogle}
        termsLink={termsLink}
        privacyLink={privacyLink}
      />
      {isLogin ? (
        <LoginForm
          className={css.form}
          onSubmit={(values) =>
            submitLogin(values).then((resp) => {
              // If the current user is returned, that means login was successful
              if (resp && resp.id) {
                logLogin();
                onSuccess();
              }
            })
          }
          inProgress={authInProgress}
          authError={authError}
        />
      ) : (
        <SignupForm
          className={css.form}
          onSubmit={handleSubmitSignup}
          inProgress={authInProgress}
          redirectFrom={authFormFrom}
          termsLink={termsLink}
          privacyLink={privacyLink}
          authInfo={authInfo}
          doesBrandUseLoyaltyPoints={doesBrandUseLoyaltyPoints}
        />
      )}
    </Box>
  );
};

AuthenticationFormComponent.defaultProps = {
  loginError: null,
  signupError: null,
  tab: 'signup',
  bannerText: null,
  from: null,
  loginTabProperties: {},
  signupTabProperties: {},
  onSuccess: () => {},
  className: null,
};

const { bool, func, object, string, oneOf, shape, node } = PropTypes;

AuthenticationFormComponent.propTypes = {
  authInProgress: bool.isRequired,
  loginError: propTypes.error,
  signupError: propTypes.error,
  confirmError: propTypes.error,
  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  idpAuthRequest: func.isRequired,
  handleIdpAuth: func.isRequired,
  tab: oneOf(['login', 'signup', 'confirm']),
  handleOpenTermsOfService: func.isRequired,
  handleOpenPrivacyPolicy: func.isRequired,
  bannerText: string,
  from: string,
  // Properties for the tab prop in LinkTabHorizontal
  loginTabProperties: shape({
    text: node,
    disabled: bool,
    selected: bool,
    linkProps: object,
    onClick: func,
  }),
  signupTabProperties: shape({
    text: node,
    disabled: bool,
    selected: bool,
    linkProps: object,
    onClick: func,
  }),
  onSuccess: func,
  className: string,
};

const mapStateToProps = (state) => {
  const { isAuthenticated, loginError, signupError, confirmError } = state.Auth;
  return {
    authInProgress: authenticationInProgress(state),
    isAuthenticated,
    loginError,
    signupError,
    confirmError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: (params) => dispatch(signup(params)),
  idpAuthRequest: (params) => dispatch(confirmRequest(params)),
  handleIdpAuth: (params) => dispatch(idpAuthentication(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationForm = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AuthenticationFormComponent);

export default AuthenticationForm;
